"use strict";
import Target from "../Target/Target";
import Result from "../Result/ResultJS";
import Col from "react-bootstrap/Col";
import React from "react";
function Playground({updateCounter, counter, isGameRunning, GameWillStart, loadingCountDown,showScore}) {

    if(GameWillStart === true){
        return (<div className="playground" >
            <Col className='game-loading'>
                <label className='bold-label'>WARM UP WILL START IN {loadingCountDown} SECONDS </label>
            </Col>
        </div>);
    }

    if (isGameRunning === true)
    {
        return (
            <div className="playground" >
                <Target updateCounter={updateCounter}/>
            </div>
        );
    }
    if(showScore === true){
        return (
            <div className="playground" >
                <Result counter={counter}/>
            </div>
        );
    }
    return (
        <div className="playground" >
        </div>
    );


}
export default Playground;