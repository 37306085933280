import DifficultyBar from "./Components/Difficulty/DifficultyBar";
import Playground from "./Components/Playgroun/PlayGround";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, {useCallback, useEffect} from 'react';
import { useState } from 'react';
import Slider from "rc-slider";

function App() {

    const [Timing, setTiming] = useState(30);
    const [counter, setCounter] = useState(0);
    const [gameWillStart, setGameWillStart] = useState(false);
    const [loadingGameCounter, setLoadingGameCounter] = useState( 3);
    const [isGameRunning, setIsGameRunning] = useState(false);
    const [buttonClick, setButtonClick] = useState(false);
    const [showScore, setShowScore] = useState(false);

    const setGameTiming = (event) => {
        setTiming(event);
    }

    const updateCounter = () => {
        setCounter((counter) => counter + 1);
    };

    useEffect(() => {
        if(buttonClick === true){
            if (loadingGameCounter === 0){
                setGameWillStart(false);
                setIsGameRunning(true);
            }
            const countdownInterval = setInterval(() => {
                if (loadingGameCounter > 0) {
                    setLoadingGameCounter(loadingGameCounter - 1);
                }
            }, 1000);
            return () => clearInterval(countdownInterval);
        }
        setButtonClick(false);
    }, [loadingGameCounter, buttonClick]);

    const StartGame = () => {
        setTiming(Timing);
        setButtonClick(true);
        setLoadingGameCounter(3);
        setGameWillStart(true);
        setCounter(0);
        setIsGameRunning(false);
    };

    useEffect(() => {
        if(isGameRunning === true){
            const countdownIntervalCurrentGame = setInterval(() => {
                if (Timing > -1) {
                    setTiming(Timing - 1);
                    if(Timing === 0){
                        setIsGameRunning(false);
                        setShowScore(true);
                        clearInterval(countdownIntervalCurrentGame)
                    }
                }
            }, 1000);
            return () => clearInterval(countdownIntervalCurrentGame);
        }
    }, [isGameRunning, Timing]);


    return (
      <div className="App">
          <Container fluid="md">
              <Row>
                  <Col md={12}>
                      <label></label>
                  </Col>
              </Row>
          </Container>
          <Container fluid="md">
              <Row>
                  <Col md={2}>
                      <label>SET TIMING (SECONDS)</label>
                  </Col>
                  <Col  md={5}>
                          <Slider onChange={setGameTiming} min={10} max={60} defaultValue={30} marks={{ 10: 10, 20: 20, 30: 30, 40: 40, 50: 50, 60: 60 }} step={null} />
                  </Col>
                  <Col  md={5}>
                      <button onClick={StartGame}>  START GAME </button>
                  </Col>
              </Row>
          </Container>
          <Playground updateCounter={updateCounter} counter={counter} isGameRunning={isGameRunning} GameWillStart={gameWillStart} loadingCountDown={loadingGameCounter} showScore={showScore}/>
      </div>
  );
}
export default App;
