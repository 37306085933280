import Col from "react-bootstrap/Col";
import React from "react";
function Result({counter}) {
    return(
        <Col className='game-loading'>
            <label  className='bold-label'>YOU SCORED {counter} POINTS </label>
        </Col>
    );
}
export default Result;
