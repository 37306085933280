"use strict";
import React, { useState } from 'react';

function Target({updateCounter}) {
    const [dotPosition, setDotPosition] = useState({ x: 50, y: 50 });
    const [isDotVisible, setIsDotVisible] = useState(true);
    const [counter, setCounter] = useState(0);

    const handleDotClick = () => {
        // Generate random positions for the dot within the div
        const maxX = 100; // Adjust this value as needed
        const maxY = 100; // Adjust this value as needed
        const newX = Math.floor(Math.random() * maxX);
        const newY = Math.floor(Math.random() * maxY);

        setDotPosition({ x: newX, y: newY });
        setIsDotVisible(true);
        updateCounter(counter + 1);
    };

    const dotStyle = {
        left: dotPosition.x + '%',
        top: dotPosition.y + '%',
    };

    return (
        <div onClick={handleDotClick} className="dot" style={dotStyle}></div>
    );
}

export default Target;